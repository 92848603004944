<template>
    <div>
        <div class="">
            <a class="home-btn" @click="winback()" href="javascript:;"><i class="el-icon-s-home"></i> 返回主页</a>
        </div>
        <pre class="content" v-html="info.description"></pre>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                info: {},
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData();
        },
        methods: {
            async getData() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/v2.pageants/current");
                if (code == 200) {
                    this.info = data;
                }
            },
        },
        watch: {
            $route() {
                this.getData();
            },
        },
    };
</script>
<style lang="less" scoped>
    .home-btn {
        padding: 10px;
        display: inline-block;
    }

    .content {
        line-height: 1.5;
        font-size: 16px;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .content {
            width: 90vw;
            margin-left: 5vw;

        }
    }
</style>
<style lang="less">
    video,
    img {
        max-width: 100%;
        object-fit: contain;
        max-height: 50vh;
    }
</style>